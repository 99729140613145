<template>
  <div class="about-us">
    <Carousel />
    <h2>产品下载</h2>
    <ul>
      <li
        v-for="(item, index) in viewModel.aboutUsList"
        :key="index"
      >
        <div
          class="icon"
          :class="item.iconClass"
        />
        <div>
          <h3>{{ item.productName }}</h3>
          <p>{{ item.text }}</p>
        </div>
        <button class="download">
          <span class="download-icon" />
          <a :href="item.downloadUrl">免费下载</a>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import Carousel from '@/components/carousel/carousel.vue'
import { defineComponent, reactive } from '@vue/composition-api'
import { useReflectiveInjector } from '@zhfe/vue2-di-plugin'
import { ConfigService } from '../../service/config.service'

export default defineComponent({
  name: 'AboutUs',
  components: {
    Carousel,
  },
  setup () {
    const injector = useReflectiveInjector()
    const config = injector.get(ConfigService)
    const viewModel = reactive({
      aboutUsList: [
        {
          iconClass: 'pdf-logo',
          productName: '可牛PDF',
          text: '一键将任何PDF文档转换为可编辑的Word文档',
          downloadUrl: config.downloadUrl.pdf
        },
        {
          iconClass: 'picture-logo',
          productName: '可牛看图',
          text: '速度快、体积小、看图方便的软件',
          downloadUrl: config.downloadUrl.picture
        },

        {
          iconClass: 'computer-logo',
          productName: '可牛清理大师',
          text: '专业、轻巧、快速，系统清理优化工具',
          downloadUrl: config.downloadUrl.computer
        },
        {
          iconClass: 'compress-logo',
          productName: '可牛压缩',
          text: '安全、高效、简洁的解压软件',
          downloadUrl: config.downloadUrl.compress
        }
      ]
    })

    return {
      viewModel
    }
  }
})
</script>

<style scoped lang="scss">
.about-us {
  h2 {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #191140;
    margin-bottom: 40px;
    margin-top: 90px;
  }
  ul {
    display: flex;
    justify-content: center;
    margin: auto;
    flex-wrap: wrap;
    margin-bottom: 90px;
    width: 1204px;
    li {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      width: 590px;
      height: 160px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #ebebeb;
      margin-right: 20px;
      margin-top: 20px;
      &:nth-child(-n + 2) {
        margin-top: 0;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
      .icon {
        background: red;
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        margin-left: 32px;
        margin-right: 20px;

        &.pdf-logo {
          background: url("../../assets/images/pdf-logo.png") no-repeat;
        }
        &.picture-logo {
          background: url("../../assets/images/picture-logo.png") no-repeat;
        }
        &.computer-logo {
          background: url("../../assets/images/computer-logo.png") no-repeat;
        }
        &.compress-logo {
          background: url("../../assets/images/compress-logo.png") no-repeat;
        }
        &.icon {
          background-size: 100% 100%;
        }
      }
      h3 {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
      }
      p {
        font-size: 14px;
        color: #999999;
        margin-top: 8px;
        line-height: 20px;
      }
      .download {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-align: center;
        border: none;
        box-sizing: border-box;
        width: 120px;
        height: 36px;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        background: linear-gradient(318deg, #FF5E00 0%, #FF4E4E 100%);
        background-size: 100% 100%;
        margin-left: auto;
        margin-right: 32px;
        a {
          text-decoration: none;
          color: #fff;
        }
        .download-icon {
          margin-right: 8px;
          width: 12px;
          height: 14px;
          background: url("../home/_images/product-download-icon.png") no-repeat center;
          background-size: 100% 100%;
        }

        &:hover {
          // border-radius: 10px;
        }
      }
    }
  }
}
</style>
